import "../../../Assets/Css/QueenBase/Users/CreateUser.css";
import { Checkbox, Input, Panel, Select, SelectGroupTitle, SelectOption, Switch } from "appkit-react";
import { Formik } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import * as Yup from "yup";

import ButtonPrimary from "../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import ButtonSecondary from "../../../Assets/Components/ButtonSecondary/ButtonSecondary";
import ConfirmBoxExitForm from "../../../Assets/Components/ConfirmBoxExitForm";
import icongroup from "../../../Assets/Icons/avatar-outline.svg";
import { PHONE_REGEX, FIELD_LENGTH_REGEX } from "../../../Constants/regex";
import UserService from "../../../Services/QueenBase/Users/UserService";
import { capitaliseFirstLetter } from "../../../Services/Shared/Handlers";
import { DataContext } from "../../../Services/Shared/Store";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import displayToastMessage from "../Root/displayToastMessage";

// USER FORM TO EDIT A USER
function EditUser({ userData, onCancel, fetchUserAfterEdit }) {
  const [openConfirmBox, setOpenConfirmBox] = useState(false); // this value handles the confirm box of exit form
  const [countries, setCountries] = useState([]);
  const [positions, setPositions] = useState([]);
  const [customselect, setCustomselect] = useState("");
  const [customcreate, setCustomcreate] = useState("");
  const { getUserLanguage, userLanguage, fetchCountriesList, formGotOpened, formGotClosed, isFormOpen } =
    useContext(DataContext);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [prefix, setPrefix] = useState([]);
  const language = getUserLanguage();
  const polyglot = polyglotLoader(language);

  // FETCH POSITION COUNTRY FROM API
  async function getPositionCountry() {
    var res = await UserService.get().getPositionCountry();
    if (res.CODE) {
      setPositions(res.positions);
    } else {
      displayToastMessage(res.MSG, "error");
    }
  }
  const initialValues = {
    inactive: userData.inactive,
    firstname: userData.firstname,
    lastname: userData.lastname,
    email: userData.email,
    positionid: userData.positionid,
    countryid: userData.countryid,
    entity: userData.entity,
    department: userData.department,
    phone: userData.phone,
    istemp: userData.istemp,
    prefixID: userData.prefixID,
  };
  // VALIDATION OF THE USER FORM
  const validationSchema = Yup.object().shape({
    prefixID: Yup.string().required("* Salutation is required"),
    firstname: Yup.string()
      .matches(FIELD_LENGTH_REGEX, polyglot.t("field_validaton"))
      .required(polyglot.t("First Name is required"))
      .trim(),
    lastname: Yup.string()
      .matches(FIELD_LENGTH_REGEX, polyglot.t("field_validaton"))
      .required(polyglot.t("Last Name is required"))
      .trim(),
    email: Yup.string().email(polyglot.t("Invalid email")).required(polyglot.t("Email is required")),
    positionid: Yup.string().required("Position is required"),
    countryid: Yup.number().min(1, polyglot.t("Country is required")),
    entity: Yup.string()
      .matches(FIELD_LENGTH_REGEX, polyglot.t("field_validaton"))
      .required(polyglot.t("Organisation is required"))
      .trim(),
    department: Yup.string()
      .matches(FIELD_LENGTH_REGEX, polyglot.t("field_validaton"))
      .required(polyglot.t("Department is required"))
      .trim(),
    phone: Yup.string()
      .matches(PHONE_REGEX, polyglot.t("Invalid phone number"))
      .min(5, polyglot.t("Phone number must be at least 5 characters"))
      .max(20, polyglot.t("Phone number must be at most 20 characters"))
      .required(polyglot.t("Phone Number is required")),
  });

  // CREATE OR SELECT A POSITION
  const selectcreate = (value, actionMeta) => {
    if (actionMeta.action === "select-option") {
      // CHECK IF EXISTING POSITIONN IS CHOOSEN
      setCustomselect(value.value);
    } else if (actionMeta.action === "create-option") {
      // IF NEW POSITION HAS TO BE CREATED
      setCustomcreate(value.label);
    }
  };

  // UPDATE USER FUNCTION ON SUBMITTING FORM
  const updateUser = async (user) => {
    setDisableSubmit(true);
    if (user.positionid.positionid) {
      // slected an existing position
      user.positionID = user.positionid.positionid;
      user.createNewPosition = false;
    } else {
      // if new position is created
      user.createNewPosition = true;
      user.positionName = user.positionid.label;
    }
    var res = await UserService.get().updateUser({
      ...user,
      userid: userData.userid,
      istemp: user.istemp ? 1 : 0,
      inactive: user.inactive ? 1 : 0,
    });
    if (res.CODE) {
      // USED TO CAPITALISE THE FIRST LETTER FOR THE NAME
      const updatedUserName = capitaliseFirstLetter(res.updatedUserName);
      displayToastMessage(polyglot.t("user_updated", { name: updatedUserName }), "success");
      fetchUserAfterEdit();
      onCancel(userData.userid);
      formGotClosed();
    } else {
      displayToastMessage(res.MSG, "error");
      setDisableSubmit(false);
    }
  };

  // RUNS ON LOAD
  useEffect(() => {
    window.scrollTo(0, 0);
    getPositionCountry();
  }, []);

  useEffect(() => {
    getPrefix(language);
  }, [language, userLanguage]);

  // FETCH PRIORITY FROM API
  async function getPrefix(language) {
    var res = await UserService.get().getPrefixNames(language);
    if (res.CODE) {
      setPrefix(res.prefixNames);
    } else {
      displayToastMessage(polyglot.t(res.MSG), "error");
    }
  }

  // get countries from api on language change
  const getCountries = async () => {
    const countriesList = await fetchCountriesList();
    setCountries(countriesList);
  };
  // Runs when user language got updated
  useEffect(() => {
    getCountries();
  }, [userLanguage]);

  // GET SELECTABLE POSITIONS
  const getSelectablePositions = () => {
    const formattedPositions = [];
    let obj = {};
    positions.forEach((each) => {
      obj = { ...each };
      obj.value = each.positionid;
      obj.label = each.name;
      formattedPositions.push(obj);
    });
    return formattedPositions;
  };
  // GET POSITION OBJECT BASED ON POSITION ID
  const getPositionObjBasedOnID = (positionID) => {
    const selectablePositions = getSelectablePositions();
    return selectablePositions.find((each) => each.positionid === positionID);
  };

  // check if form is empty before canceling the form
  const checkIfFormIsEmptyAndGoback = (user) => {
    if (isFormOpen) {
      // show confirm box
      toggleConfirmBox();
    } else {
      onCancel(user);
    }
  };

  // TOGGLE CONFIRM BOX BEFORE CLOSING THE FORM
  const toggleConfirmBox = () => {
    setOpenConfirmBox(!openConfirmBox);
  };

  // CLOSE FORM AND NAVIGATE TO VIEW PROJECTS
  const closeFormAndRedirect = (user) => {
    toggleConfirmBox();
    onCancel(user);
    formGotClosed();
  };

  return (
    <div className="Edit-user-panel">
      <Panel>
        <Formik onSubmit={updateUser} initialValues={initialValues} validationSchema={validationSchema}>
          {(prop) => {
            const { values, handleChange, handleSubmit, errors, touched, dirty } = prop;
            // FORMIK FORM - HANDLE FORMOPEN OR CLOSE STATUS
            const updateFormStatusBasedOnDirty = () => {
              if (dirty) {
                formGotOpened();
              }
              if (!dirty) {
                formGotClosed();
              }
            };
            setTimeout(() => {
              updateFormStatusBasedOnDirty();
            }, 0);
            return (
              <div className="row fadeIn mb-4 a-panel-user">
                <div className="col-md-12">
                  <div className="UserEditMode">
                    <div className="row">
                      <div className="col-md-6 usertitle-view-text">
                        <div className="subtitle_two_title_qb v-a-t">
                          <img alt="icongroup" src={icongroup} className="UserIconBig" />
                          &nbsp;&nbsp;
                          {userData.firstname}
                        </div>
                      </div>
                      <div className="col-md-6  toggle-text ">
                        <div className="subtitle_two_title_qb row switch-user">
                          <Switch
                            key={values.inactive}
                            onChange={(value) => handleChange("inactive")({ target: { value } })}
                            checked={values.inactive ? true : false}
                            ariaLabel={
                              values.inactive ? polyglot.t("user deactivated") : polyglot.t("user activated")
                            }
                            label={polyglot.t("Deactivate")}
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="row mt-3">
                        <div className="col-md-3">
                          {getUserLanguage() === "en" && (
                            <>
                              <Select
                                placeholder={polyglot.t("Salutation *")}
                                value={Number(values.prefixID).toString()}
                                onSelect={(value) =>
                                  handleChange("prefixID")({
                                    target: { value },
                                  })
                                }
                              >
                                {prefix.map((prefix, index) => {
                                  return (
                                    <SelectOption key={index} value={Number(prefix.id).toString()}>
                                      {polyglot.t(prefix.name)}
                                    </SelectOption>
                                  );
                                })}
                              </Select>
                            </>
                          )}
                          {getUserLanguage() === "ger" && (
                            <>
                              <Select
                                placeholder={polyglot.t("Salutation *")}
                                value={Number(values.prefixID).toString()}
                                onSelect={(value) =>
                                  handleChange("prefixID")({
                                    target: { value },
                                  })
                                }
                              >
                                {prefix.map((prefix, index) => {
                                  return (
                                    <SelectOption key={index} value={Number(prefix.id).toString()}>
                                      {polyglot.t(prefix.name)}
                                    </SelectOption>
                                  );
                                })}
                              </Select>
                            </>
                          )}
                          {errors.prefixID && touched.prefixID ? (
                            <div className="error">{polyglot.t(errors.prefixID)}</div>
                          ) : null}
                          <br />
                        </div>
                        <div className="col-md-3">
                          <Input
                            inputBoxSize="sm"
                            placeholder={polyglot.t("First name *")}
                            value={values.firstname}
                            onChange={handleChange("firstname")}
                            aria-label={polyglot.t("first name of the user")}
                          />
                          {errors.firstname && touched.firstname ? (
                            <div className="error">{errors.firstname}</div>
                          ) : null}

                          <br />
                        </div>
                        <div className="col-md-6">
                          <Input
                            inputBoxSize="sm"
                            placeholder={polyglot.t("Last Name *")}
                            value={values.lastname}
                            onChange={handleChange("lastname")}
                            aria-label={polyglot.t("last name of the user")}
                          />
                          {errors.lastname && touched.lastname ? (
                            <div className="error">{errors.lastname}</div>
                          ) : null}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <Input
                            inputBoxSize="sm"
                            placeholder={polyglot.t("Email*")}
                            value={values.email}
                            disabled={true}
                            onChange={handleChange("email")}
                            aria-label={polyglot.t("email id")}
                          />
                          {errors.email && touched.email ? <div className="error">{errors.email}</div> : null}
                        </div>
                        <div className="col-md-6" id="CreatableSelect">
                          {positions.length === 0 && (
                            <CreatableSelect
                              placeholder={polyglot.t("Position *")}
                              options={[{ label: polyglot.t("Loading...") }]}
                              classNamePrefix="custom-creatable-select"
                              aria-label={polyglot.t("loading")}
                            />
                          )}
                          {getSelectablePositions().length > 0 && (
                            <CreatableSelect
                              placeholder={polyglot.t("Position *")}
                              value={getPositionObjBasedOnID(values.positionid)}
                              onInputChange={(value, actionMeta) => {
                                handleChange("newposition")({
                                  target: { value },
                                });
                                selectcreate(value, actionMeta);
                              }}
                              onChange={(value, actionMeta) => {
                                handleChange("positionid")({
                                  target: { value },
                                });
                                selectcreate(value, actionMeta);
                              }}
                              options={getSelectablePositions()}
                              classNamePrefix="custom-creatable-select"
                              aria-label={polyglot.t("position")}
                            />
                          )}
                          {errors.positionid && touched.positionid ? (
                            <div className="error">{polyglot.t(errors.positionid)}</div>
                          ) : (
                            null &&
                            errors.positionid && (
                              <span className="error">{polyglot.t(errors.positionid)}</span>
                            )
                          )}
                          <br />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          {countries.length > 0 && (
                            <Select
                              placeholder={polyglot.t("Country")}
                              showSearchOnToggle={true}
                              value={values.countryid}
                              onSelect={(value) =>
                                handleChange("countryid")({
                                  target: { value },
                                })
                              }
                              aria-label={polyglot.t("Country_noStar")}
                            >
                              <SelectGroupTitle>{polyglot.t("Country_noStar")} </SelectGroupTitle>
                              {countries.map((country, index) => {
                                if (index < 1)
                                  return (
                                    <SelectOption key={index + 1} value={country.countryid}>
                                      {country.name}
                                    </SelectOption>
                                  );
                              })}
                              <hr className="" />
                              {countries.map((country, index) => {
                                if (index > 0)
                                  return (
                                    <SelectOption key={index + 1} value={country.countryid}>
                                      {country.name}
                                    </SelectOption>
                                  );
                              })}
                            </Select>
                          )}
                          {errors.countryid && touched.countryid ? (
                            <div className="error">{errors.countryid}</div>
                          ) : (
                            null && errors.countryid && <span className="error">{errors.countryid}</span>
                          )}
                          <br />
                        </div>

                        <div className="col-md-6">
                          <Input
                            inputBoxSize="sm"
                            placeholder={polyglot.t("Organisation")}
                            value={values.entity}
                            onChange={handleChange("entity")}
                            aria-label={polyglot.t("Organisation")}
                          />
                          {errors.entity && touched.entity ? (
                            <div className="error">{errors.entity}</div>
                          ) : null}
                          <br />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <Input
                            inputBoxSize="sm"
                            placeholder={polyglot.t("Department*")}
                            value={values.department}
                            onChange={handleChange("department")}
                            aria-label={polyglot.t("Department")}
                          />
                          {errors.department && touched.department ? (
                            <div className="error">{errors.department}</div>
                          ) : null}
                        </div>
                        <div className="col-md-6">
                          <Input
                            inputBoxSize="sm"
                            placeholder={polyglot.t("PhoneNumber*")}
                            value={values.phone}
                            onChange={handleChange("phone")}
                            aria-label={polyglot.t("Phone number")}
                          />
                          {errors.phone && touched.phone ? <div className="error">{errors.phone}</div> : null}
                        </div>
                      </div>
                      <br />
                      <div className="col-md-8 checkbox-aline">
                        <div className="accept-user">
                          {userData.istemp ? (
                            <Checkbox
                              key={userData.inactive}
                              onChange={handleChange("istemp")}
                              disabled={true}
                              checked={userData.istemp ? true : false}
                              aria-label={polyglot.t("check if temporary user")}
                            >
                              <span className="check-active f-acc-B">{polyglot.t("Temporary user")}</span>
                              <br />
                            </Checkbox>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col-md-12 edit-clientform-button p-0">
                        <div className="row">
                          <div className="col-md-6">
                            <ButtonSecondary
                              onClick={() => checkIfFormIsEmptyAndGoback(userData.userid)}
                              ariaLabel={polyglot.t("Cancel edit user form")}
                            >
                              {polyglot.t("Cancel")}
                            </ButtonSecondary>
                          </div>
                          <div className="col-md-6 float-right">
                            <ButtonPrimary
                              disabled={disableSubmit}
                              onClick={handleSubmit}
                              ariaLabel={polyglot.t("Save user")}
                            >
                              {polyglot.t("Save Changes")}
                            </ButtonPrimary>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ConfirmBoxExitForm
                  show={openConfirmBox}
                  heading={"Cancel update"}
                  cancel={toggleConfirmBox}
                  proceed={() => closeFormAndRedirect(userData.userid)}
                />
              </div>
            );
          }}
        </Formik>
      </Panel>
    </div>
  );
}
EditUser.propTypes = {
  history: PropTypes.object,
  userData: PropTypes.object,
  onCancel: PropTypes.func,
  fetchUserAfterEdit: PropTypes.func,
};

export default withRouter(EditUser);
